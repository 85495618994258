import React, {useEffect, useState} from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import {Card, Carousel, Col, Container, Row} from "react-bootstrap";
import Button from '../buttons/button'
import ProductBannerNoLine from "../product_banners_no_line/product_banners_no_line";
import Banner from "../../imgs/bg-empleos-nueva.jpg";
import Img1 from "../../imgs/chica-empleos.jpg";
import Img2 from "../../imgs/chico-empleos.jpg";
import Img3 from "../../imgs/chica-postularme.jpg";
import './trabaja-con-nosotros.css';
import axios from "axios";
import { Layout } from "../Layout";
import Faq from "../faq/faq";
import useFaqEmpleos from '../faq/useFaqEmpleos';

let text = `Sumate al desafío de trabajar en la <span class="highlightsbold">Empresa N°1 del Mercado.</span>`;
const pageTitle = 'Trabajá en Balanz';
const pageDesc = 'Potenciá tu carrera laboral ingresando a la compañía líder del Mercado de Capitales. Conocé nuestras búsquedas de empleos y postulate.';


const TrabajaConNosotros = () => {
    const faq = useFaqEmpleos();

    

    const [totalData, setTotalData] = useState(0)
    const [departaments, setDepartaments] = useState([])
    const [departamentSelected, setDepartamentSelected] = useState([])
    const [jobs, setJobs] = useState([])
    const [index, setIndex] = useState(0);
    const [pageSize] = useState(8);
    const [idGeneric, setIdGeneric] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const baseUrl = `${process.env.GATSBY_BASE_URL}trabaja-con-nosotros/`;

    const searchesData = useStaticQuery(graphql`
        {
            allSearches {
                nodes {
                id_search
                position_name
                timestamp
                timestamp_approve
                seniority_name
                description_task
                profile_description
                id_departament
                departament
                area
                name
                sector
                }
            }
        }
    `);

    const selectDepartaments = (SearchData) => {
        const dData = [];
        SearchData.forEach(d => {
            let search_departament = dData.find(dD => dD.value === d.id_departament);
            if(typeof search_departament === 'undefined' && null !== d.id_departament && null !== d.departament) {
                dData.push({value: d.id_departament, label: d.departament})
            }
        })
        setDepartaments(dData)
    }

    const allData = searchesData.allSearches.nodes;
    const [data, setData] = useState(allData)

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };



    const preparePage = (data) =>{
        data = data.filter(d => {
            let check = true;
            if(departamentSelected.length > 0) {
                check = departamentSelected.includes(d.id_departament);
            }
            return check;
        })
        setTotalData(data.length)

        if(data.length > 0){
            const firstPageIndex = (currentPage - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            setJobs(data.slice(firstPageIndex, lastPageIndex))
        }else {
            setJobs([])
        }

    }

    useEffect(()=>{
        axios.get(process.env.GATSBY_RRHH_API_URL + '/v1/positions/corporate')
        .then(response => {
            const data = response.data;
            selectDepartaments(data)
            setData(data);
        })
        .catch(error => {
            console.log(error)
        })
    },[])

    useEffect(()=>{
        axios.get(process.env.GATSBY_RRHH_API_URL + '/v1/positions/generic/corporate')
        .then(response => {
            setIdGeneric(response.data.id)
        })
        .catch(error => {
            console.log(error)
        })
    },[])


    useEffect(()=>{
        preparePage(data)
    },[setJobs, currentPage, data, departamentSelected])


    const handleChange = (
        newValue,
        actionMeta
      ) => {
        setDepartamentSelected(newValue.map((obj) => obj.value));

      };

    const page = (
        <>
            <ProductBannerNoLine
                bannerContainerClassName="bannerReporte"
                bannerTrasparecy="bannerMask-noblue"
                productTitle="Trabajá con nosotros"
                productTitleBlueClassName="productTitleEmpleos"
                bannerImage={Banner}
                bannerImageClassName="ReportBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerTextInversiones"
                bannerImageMobile={Banner}
                mobile={false}
            />
        <section className="fullwidth product-description">
                
                    <Container>
                        <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                            <Col xs={12} >
                                <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5" style={{textAlign: 'justify'}}>Somos el Full Investment House líder en el mercado de capitales, estamos 
                                    comprometidos con ofrecer soluciones innovadoras de inversión que se adaptan 
                                    a las necesidades de nuestros clientes. Con más de 20 años en el mercado, 
                                    nuestra misión es ayudar a las personas a alcanzar sus objetivos financieros a 
                                    través de un servicio personalizado y productos de alta calidad.
                                </p>
                                <div className="pt-4 text-center">
                                    
                                    <Button id="ver_busquedas" className="btn-busquedas btn-empleos-blue" variant="primary" text="VER BUSQUEDAS ABIERTAS" onClick={() => { window.location.href = "/busquedas-abiertas" }} />
                                </div>
                            </Col>
                        </Row>
                    </Container>

            </section>
            <section className='section-home-five px-3 container-xl pb-5'>
                <article className='row article-top pb-5 g-0'>
                    <div className='col'>
                        <img src={Img1} alt="Employee working"/>
                    </div>
                    <div className='col my-4 my-md-auto col_text ps-md-4'>
                    <div style={{paddingLeft:'2rem'}}>
                        <h3 >Nuestra cultura</h3>
                        <p>En Balanz, impulsamos una cultura basada en la 
                            confianza y el crecimiento continuo. 
                            Fomentamos un ambiente de trabajo donde la 
                            colaboración y la autonomía permiten que cada 
                            miembro desarrolle su potencial al máximo. </p>
                    </div>
                    </div>
                </article>
                <article className='row article-bottom g-0'>
                    <div className='col my-4 my-md-auto col_text'>
                        <div style={{paddingLeft:'2rem'}}>
                            <h3>¿Por qué trabajar en Balanz?</h3>
                            <p>Unirte a Balanz es apostar por tu desarrollo 
                                profesional. Ofrecemos un entorno dinámico 
                                donde podrás aprender, crecer y contribuir a 
                                proyectos que desafían los límites de la industria 
                                financiera.  </p>
                            </div>
                    </div>
                    <div className='col'>
                        <img src={Img2} alt="Employee working"/>
                    </div>
                </article>
            </section>
            <section className="fullwidth product-description pb-0">
                <div className="bg-blue">
                    <Container>
                        <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                            <Col xs={12} >
                            <div className="d-flex justify-content-center">
                                <p className="product-text product-text-bgblue pt-2 pt-sm-3 pt-md-4 pt-lg-5" >Nuestras oficinas son espacios diseñados para la
                                    innovación y el trabajo en equipo.
                                    <br/>Aquí, la <strong>escucha activa y la comunicación</strong> son clave para 
                                    construir relaciones sólidas y alcanzar objetivos comunes. 
                                </p>
                            </div> 
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            <section className="section-home-five pt-0 pb-0">
                <article className='row article-top pb-5 g-0'>
                    <div className='col p-0 bg-grey d-flex flex-column justify-content-center align-items-center'>
                        <div className="bg-grey-text">
                            <h3>Oportunidades abiertas</h3>
                            <p>Queremos conocerte</p>
                            <div className="text-center ">
                                <Button id="ver_busquedas" className="btn-busquedas btn-empleos-blue w-100" variant="primary" text="QUIERO POSTULARME" onClick={() => { window.location.href = "/busquedas-abiertas" }} />
                            </div>
                        </div>
                    </div>
                    <div className='col p-0'>
                        <img src={Img3} className="img-work" alt="Employee working"/>
                    </div>
                </article>
            </section>
            <section className="fullwidth preguntas-frecuentes">
                <Container>
                    <Row>
                    <Faq faq={faq} customClass={'opciones-faq'} />
                    </Row>
                </Container>
            </section>
            
        </>
    )
    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"trabaja_con_nosotros"}></Layout>
        </main>
    )
}
export default TrabajaConNosotros;