import { useState, useEffect } from "react";
import { apiRequest } from "../../apiRquest/apiRequest";

const useFaqEmpleos = () => {
    const [faq, setFaq] = useState({
        list: []
    });

    useEffect(() => {
        apiRequest
            .get("/v1/faq/23")
            .then((res) => {
                const data = res.data.data.map(d => ({
                    question: d.question,
                    answer: d.answer
                }));
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.error("Error al obtener las FAQs", err);
            });
    }, []);

    return faq;
};

export default useFaqEmpleos;